<template>
  <div>
    <b-card v-if="getAppSettings.debug">
      <code
        ><pre
          >{{ this.getCurrentOrder }}
        </pre></code
      >
    </b-card>

    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section v-else>
      <b-form @submit="addRow">
        <div>
          <b-alert v-if="error" show variant="danger">{{
            error.data.errors[Object.keys(error.data.errors)[0]][0]
          }}</b-alert>

          <!-- Details START-->
          <b-card>
            <Status-Badge
              class="float-right"
              :status="getCurrentOrder.statusName"
            ></Status-Badge>
            <h2>Order no: {{ getCurrentOrder.orderNo }}</h2>
            <b-form-group
              label-cols-lg="3"
              label="Project"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
            >
              <b-collapse id="collapse-details">
                <b-form-group v-if="getAppSettings.useProjectNo">
                  <label><i class="fas fa-sitemap"></i> Project no</label>
                  <b-input
                    type="text"
                    v-model="getCurrentOrder.projectNo"
                  ></b-input>
                </b-form-group>

                <b-form-group>
                  <label>Project</label>
                  <b-input
                    type="text"
                    :readonly="this.getCurrentOrder.statusNo > '20'"
                    v-model="getCurrentOrder.projectName"
                  ></b-input>
                </b-form-group>

                <!-- To Do! Add funconality to change project -->
                <!-- <b-form-select 
                                    v-model="projectId" 
                                    :options="allProjects.map(x => 
                                    ({
                                        value : x.id,
                                        text : x.name
                                    }))"
                                    @change="setPro(projectId)">
                                </b-form-select> -->

                <b-form-group v-if="this.getUser.role === 'Admin'">
                  <label>Status</label>
                  <b-form-select
                    v-model="getCurrentOrder.statusNo"
                    :options="getStatuses"
                    ref="selectStatus"
                    @change="updateStatusName"
                  ></b-form-select>
                </b-form-group>

                <b-form-group>
                  <label>Description</label>
                  <b-form-textarea
                    v-model="getCurrentOrder.description"
                    :readonly="this.getCurrentOrder.statusNo > '20'"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label><i class="fas fa-sticky-note"></i> Notes</label>
                  <b-form-textarea
                    v-model="getCurrentOrder.notes"
                    :readonly="this.getCurrentOrder.statusNo > '20'"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-collapse>
            </b-form-group>
            <b-button v-b-toggle.collapse-details class="m-2"
              >Show / hide project details</b-button
            >
            <!-- </b-card> -->
            <!-- Details END -->

            <!-- Contact START -->
            <!-- <b-card > -->
            <b-form-group
              label-cols-lg="3"
              label="Contact"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
            >
              <b-collapse id="collapse-person">
                <b-form-group id="person">
                  <label
                    ><i class="fas fa-address-book"></i> Contact person *</label
                  >
                  <b-input
                    type="text"
                    v-model="getCurrentOrder.orderedBy"
                    :readonly="this.getCurrentOrder.statusNo > '20'"
                    required
                    placeholder="Enter contact person"
                  >
                  </b-input>
                </b-form-group>
                <b-form-group id="mail">
                  <label
                    ><i class="fas fa-envelope-open-text"></i> Email *</label
                  >
                  <b-input
                    type="text"
                    v-model="getCurrentOrder.mail"
                    :readonly="this.getCurrentOrder.statusNo > '20'"
                    required
                    placeholder="Enter e-mail"
                  ></b-input>
                </b-form-group>
                <b-form-group id="phone">
                  <label><i class="fas fa-phone"></i> Phone *</label>
                  <b-input
                    type="text"
                    class="form-control"
                    v-model="getCurrentOrder.phone"
                    required
                    :readonly="this.getCurrentOrder.statusNo > '20'"
                    placeholder="Phone"
                  >
                  </b-input>
                </b-form-group>
              </b-collapse>
            </b-form-group>
            <b-button v-b-toggle.collapse-person class="m-2"
              >Show / hide contact details</b-button
            >
            <!-- </b-card> -->
            <!-- Contact END -->

            <!-- Delivery START -->
            <!-- <b-card > -->
            <b-form-group
              label-cols-lg="3"
              label="Delivery"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
            >
              <b-collapse id="collapse-delivery">
                <b-container>
                  <b-form-group>
                    <label
                      ><i class="fas fa-calendar-alt"></i> Preferred delivery
                      date</label
                    >
                    <b-form-input
                      id="preferredDeliveryDate"
                      type="date"
                      :readonly="this.getCurrentOrder.statusNo > '20'"
                      v-model="preferredDeliveryDate"
                    ></b-form-input>
                  </b-form-group>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <label
                          ><i class="fas fa-map-marker-alt"></i> Street name
                          *</label
                        >
                        <b-form-input
                          id="streetName"
                          type="text"
                          :readonly="this.getCurrentOrder.statusNo > '20'"
                          required
                          v-model="getCurrentOrder.deliveryAddress.streetName"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <label>Zip code *</label>
                        <b-form-input
                          id="postalCode"
                          type="text"
                          :readonly="this.getCurrentOrder.statusNo > '20'"
                          required
                          v-model="getCurrentOrder.deliveryAddress.postalCode"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <label>City *</label>
                        <b-form-input
                          id="city"
                          type="text"
                          :readonly="this.getCurrentOrder.statusNo > '20'"
                          required
                          v-model="getCurrentOrder.deliveryAddress.city"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-form-group>
                    <label> Contact person on-site *</label>
                    <b-form-input
                      id="contactPersonOnSite"
                      type="text"
                      :readonly="this.getCurrentOrder.statusNo > '20'"
                      required
                      v-model="getCurrentOrder.contanctPersonOnSite"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <label> Contact person on-site phone *</label>
                    <b-form-input
                      id="contactPersonOnSitePhone"
                      type="text"
                      :readonly="this.getCurrentOrder.statusNo > '20'"
                      required
                      v-model="getCurrentOrder.contactPersonOnSitePhone"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <label> Comments</label>
                    <b-form-input
                      id="contactPersonOnSiteComments"
                      type="text"
                      :readonly="this.getCurrentOrder.statusNo > '20'"
                      v-model="getCurrentOrder.contactPersonOnSiteComments"
                    ></b-form-input>
                  </b-form-group>
                </b-container>
              </b-collapse>
            </b-form-group>
            <b-button v-b-toggle.collapse-delivery class="m-2"
              >Show / hide delivery details</b-button
            >
          </b-card>
          <!-- Delivery END -->

          <Attachment-List :order="order"></Attachment-List>
          <!-- Actions -->
          <b-card>
            <Add-Item-Button
              v-if="this.getCurrentOrder.statusNo <= '20'"
            ></Add-Item-Button>
            <b-button
              variant="outline-secondary"
              class="mr-2"
              @click="addAttachment"
            >
              <i class="fas fa-plus"></i> Add attachment
            </b-button>
            <b-button
              v-if="this.getCurrentOrder.statusNo <= '20'"
              class="mr-2"
              variant="outline-secondary"
              @click="addRow"
              ><i class="fas fa-plus"></i> Add row</b-button
            >
            <b-button
              variant="primary"
              @click="sendOrder"
              class="mr-2"
              v-if="this.getCurrentOrder.statusNo === '1'"
              ><i class="fas fa-envelope-open-text"></i> Send
            </b-button>
            <b-button
              class="mr-2"
              variant="outline-secondary"
              @click="toggleTable"
            >
              <span v-if="this.showTable"
                ><i class="fas fa-toggle-on"></i> Table</span
              >
              <span v-else><i class="fas fa-toggle-off"></i> Table</span>
            </b-button>
            <b-button class="mr-2" variant="default" @click="seeChanges">
              <i class="fas fa-history"></i> History
            </b-button>
            <b-button variant="success" class="mr-2" @click="onSubmit"
              ><i class="fas fa-save"></i> Save</b-button
            >
            <b-button type="submit" hidden></b-button>
            <b-button class="mr-2" variant="danger" @click="goBack">
              <i class="fas fa-window-close"></i> Cancel
            </b-button>
          </b-card>
          <!-- Actions ends -->

          <!-- Order lines START -->
          <Order-Lines-Card
            :order="getCurrentOrder"
            v-if="!showTable"
          ></Order-Lines-Card>
          <Order-Lines-Table
            :order="getCurrentOrder"
            :isAdmin="true"
            v-else
          ></Order-Lines-Table>
        </div>
      </b-form>
      <Add-Item-Modal></Add-Item-Modal>
      <Edit-Item-Modal></Edit-Item-Modal>
      <Attachment-Order-Modal :order="getCurrentOrder"></Attachment-Order-Modal>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrderLinesTable from "@/components/OrderLinesTable";
import OrderLinesCard from "@/components/OrderLinesCard";
import AddItemModal from "@/components/AddItemModal";
import EditItemModal from "@/components/EditItemModal";
import AddItemButton from "@/components/Buttons/AddItemButton";
import StatusBadge from "@/components/StatusBadge";
import axios from "axios";
import config from "@/store/config.js";
import moment from "moment";
import AttachmentOrderModal from "@/components/AttachmentOrderModal";
import AttachmentList from "@/components/AttachmentList";

export default {
  name: "orderEdit",
  components: {
    OrderLinesTable,
    OrderLinesCard,
    AddItemModal,
    EditItemModal,
    AddItemButton,
    StatusBadge,
    AttachmentOrderModal,
    AttachmentList
  },
  data() {
    return {
      file: "",
      error: null,
      isLoading: true,
      query: "",
      item: {},
      contactDetails: null,
      projectId: "",
      orderId: null,
      showTable: true
    };
  },
  methods: {
    addRow(evt) {
      evt.preventDefault();
      this.addNewRow();
    },
    sendOrder() {
      //TODO: validation!
      let self = this;

      axios
        .get(`${config.API}/status/10`)
        .then(r => {
          self.order.statusNo = r.data.statusNo;
          self.order.statusName = r.data.name;

          self.$store
            .dispatch("updateCreateCurrentOrder", self.getCurrentOrder)
            .then(function() {
              self.$router.push(`/order/view/${self.orderId}`);
              self.error = null;
            })
            .catch(e => {
              self.error = e;
            });
        })
        .catch(e => {
          self.error = e;
        });
    },
    updateStatusName(status) {
      this.getCurrentOrder.statusName = this.$refs.selectStatus.options
        .filter(x => x.value === status)
        .map(x => {
          return x.text;
        })[0]
        .toString();
    },
    ...mapActions([
      "loadStatuses",
      "loadOrder",
      "addItemToOrder",
      "updateCreateCurrentOrder",
      "setNewFormItem",
      "setProject",
      "loadSettings",
      "addNewRow"
    ]),
    onSubmit(evt) {
      evt.preventDefault();
      var self = this;
      window.scrollTo(0, 0);
      self.isLoading = true;
      this.updateCreateCurrentOrder(this.getCurrentOrder)
        .then(() => {
          self.$router.push(`/order/view/${this.orderId}`);
        })
        .catch(e => {
          self.isLoading = false;
          self.error = e;
        });
    },
    toggleTable() {
      this.showTable = !this.showTable;
    },
    setPro(id) {
      if (id) {
        this.projectId = id;
        // localStorage.setItem('activeProjectId',`${i}`);
        this.setProject(id);
      }
    },
    seeChanges() {
      this.$router.push(`/order/history/${this.orderId}`);
    },
    addAttachment() {
      this.$bvModal.show("attachmentOrderModal");
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    this.loadSettings();
    this.orderId = this.$route.params.id || null;

    let self = this;
    this.loadStatuses();
    if (this.orderId) {
      this.loadOrder(this.orderId)
        .then(r => {
          self.order = r.data;
          self.shippingDate = moment(r.data.shippingDate).format("YYYY-MM-DD");
          self.deliveryDate = moment(r.data.deliveryDate).format("YYYY-MM-DD");
          self.receivedDate = moment(r.data.receivedDate).format("YYYY-MM-DD");

          if (
            r.data.statusNo !== "10" &&
            r.data.statusNo !== "1" &&
            self.getUser.role !== "Admin"
          ) {
            self.$router.push("/order/view/" + this.orderId);
          }
          if (r.data.projectId) {
            self.setProject(r.data.projectId);
          }

          this.isLoading = false;
        })
        .catch(e => {
          self.error = e;
        });
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentProject",
      "getContactDetails",
      "getCurrentOrder",
      "getContactDetailsOptions",
      "getStatuses",
      "allProjects",
      "getUser",
      "getAllItems",
      "getCurrentItem",
      "getItemInOrderStatus",
      "getAppSettings"
    ]),
    shippingDate: {
      get() {
        return this.getCurrentOrder.shippingDate;
      },
      set(value) {
        this.getCurrentOrder.shippingDate = value;
      }
    },
    deliveryDate: {
      get() {
        return this.getCurrentOrder.deliveryDate;
      },
      set(value) {
        this.getCurrentOrder.deliveryDate = value;
      }
    },
    receivedDate: {
      get() {
        return this.getCurrentOrder.receivedDate;
      },
      set(value) {
        this.getCurrentOrder.receivedDate = value;
      }
    },
    preferredDeliveryDate: {
      get() {
        return moment(
          String(this.getCurrentOrder.preferredDeliveryDate)
        ).format("YYYY-MM-DD");
      },
      set(value) {
        this.getCurrentOrder.preferredDeliveryDate = value;
      }
    }
  }
};
</script>

<style>
.img {
  max-height: 200px;
}
</style>
