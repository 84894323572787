<template>
  <b-button class="mr-2" variant="outline-secondary" @click="showAddItemModal"
    ><i class="fas fa-plus"></i> Items</b-button
  >
</template>

<script>
export default {
  name: "AddItemButton",
  methods: {
    showAddItemModal() {
      this.$bvModal.show("addItemModal");
    }
  }
};
</script>

<style></style>
