<template>
  <!-- Modal start -->
  <section>
    <b-modal
      ref="attachtmentOrderModal"
      id="attachmentOrderModal"
      hide-footer
      title="Add Attachtment on order"
    >
      <div>
        <b-alert v-if="attachOrderModalError" show variant="danger">{{
          attachOrderModalError
        }}</b-alert>
      </div>
      <div v-if="isLoading" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <form @submit="onSubmit" v-else>
        <b-container fluid>
          <b-row class="my-1 mt-3 mb-3">
            <b-col>
              <b-form-group>
                <div>
                  <p>
                    You may upload attachment(s) on the current order. Max 10Mb.
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <vue-dropzone
            ref="uploadOrderAttachment"
            id="dropzone"
            :useCustomSlot="true"
            :options="dropzoneOptions"
            v-on:vdropzone-success="vdropzonesuccess"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                Drag and drop to upload
              </h3>
              <div class="subtitle">
                ...or click to select a file from your computer
              </div>
            </div></vue-dropzone
          >
          <!-- <Upload-Image
            :orderLine="orderLine"
            :selectedItem="selectedItem"
          ></Upload-Image> -->

          <b-row>
            <b-button variant="primary" type="submit" class="m-3">Add</b-button>
            <b-button variant="danger" @click="hideModal" class="m-3"
              >Cancel</b-button
            >
          </b-row>
        </b-container>
      </form>
    </b-modal>
  </section>
  <!-- Modal END -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import config from "@/store/config.js";
// import UploadImage from "@/components/UploadImage";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "attachmentOrderModal",
  components: {
    // UploadImage
    vueDropzone: vue2Dropzone
  },
  props: ["order"],
  data() {
    return {
      isLoading: true,
      query: "",
      attachOrderModalError: null,
      dropzoneOptions: {
        url: "",
        thumbnailWidth: 150,
        maxFilesize: 10, //MB
        acceptedFiles:
          ".PDF,.PNG,.JPG,.JPEG,.DXF,.DWG,.SKP,.doc,.docx,application/vnd.ms-excel,application/msword",
        headers: {}
      }
    };
  },
  created() {
    this.isLoading = false;
    this.dropzoneOptions.url = `${config.API}/attachment/${this.getCurrentOrder.id}`;
    const accessToken = localStorage.access_token || null;
    if (accessToken) {
      this.dropzoneOptions.headers = {
        Authorization: `Bearer ${accessToken}`
      };
    }
  },
  methods: {
    ...mapActions(["updateCreateCurrentOrder"]),
    hideModal() {
      //validates that all the required fields are added
      this.$bvModal.hide("attachmentOrderModal");
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["attachmentOrderModal"].toggle("#toggle-btn");
    },
    onSubmit(evt) {
      evt.preventDefault();
      window.scrollTo(0, 0);

      if (this.order.id) {
        this.updateCreateCurrentOrder(this.order)
          .then(() => {
            this.$bvModal.hide("attachmentOrderModal");
          })
          .catch(e => {
            this.attachOrderModalError = e;
          });
      } else {
        this.attachOrderModalError = "Missing order ID!";
      }
    },
    vdropzonesuccess: function(file, response) {
      this.order.attachments = [
        {
          url: response
        }
      ];
    }
  },
  computed: {
    ...mapGetters([
      "getAllItems",
      "getEditorOrderLineId",
      "getCurrentProject",
      "getCurrentOrder",
      "getCurrentOrderLine",
      "getCurrentItem"
    ])
  }
};
</script>
